import React from 'react';
// Customizable Area Start
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";

import {choseedate,appointment,map}  from "./assets";
import {
  
  Button,
  Box,
  styled
} from "@mui/material";
import "../../email-account-registration/src/EmailAccountRegistratin.css"

// Customizable Area End

import AppointmentsController, {
  Props,
  configJSON,
} from './AppointmentsController';
import { TimeSlot } from './types';

export default class Appointments extends AppointmentsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  buttonTabs = () => {
    return (
      <>
       <Tabbox>
        <Buttonbox>
          <Singlebuttonbox>
            <Button style={this.state.activeTab === '1' ? webstyle.activeButoon : webstyle.notactiveButton} onClick={() => this.setactiveTab("1")} data-test-id="choosedateid">
              <img src={choseedate.default} style={webstyle.choosedateimage} /> All
            </Button>
          </Singlebuttonbox>
          <Singlebuttonbox>
            <Button style={this.state.activeTab === '2' ? webstyle.activeButoon : webstyle.notactiveButton} onClick={() => this.setactiveTab("2")} data-test-id="planyourrideid">Scheduled</Button>
          </Singlebuttonbox>
          <Singlebuttonbox>
            <Button style={this.state.activeTab === '3' ? webstyle.activeButoon : webstyle.notactiveButton} onClick={() => this.setactiveTab("3")} data-test-id="upcomingrideid">Completed</Button>
          </Singlebuttonbox>
          <Singlebuttonbox>
            <Button style={this.state.activeTab === '4' ? webstyle.activeButoon : webstyle.notactiveButton} onClick={() => this.setactiveTab("4")} data-test-id="upcomingrideid">Cancelled</Button>
          </Singlebuttonbox>
        </Buttonbox>
        <ContentContainer>
          <ContentBox>
            {this.screentab()}
          </ContentBox>
        </ContentContainer>
      </Tabbox>
      </>

    )
  }

  screentab = () => {
    switch (this.state.activeTab) {
      case '1':
       return <div className='firstdiv'>{this.renderTable("1")}</div>

      case '2':
        return <div className='firstdiv'>{this.renderTable("2")}</div>
      case '3':
        return <div className='firstdiv'>{this.renderTable("3")}</div>
        case '4':
          return <div className='firstdiv'>{this.renderTable("4")}</div>
      default:
        return <div className='firstdiv'>{this.renderTable("5")}</div>
    }
  }

  renderTable = (currenttab:string) => {   
    let sampleData = this.getSampleData(currenttab); 
    return (

      <div style={{padding:'20px',backgroundColor:'white',borderRadius:'20px',}}>
    <div className="table-container">
  <table className="custom-table">
    <thead>
      <tr className="header-row">
        <th>Job ID</th>
        <th>Pickup Location</th>
        <th>Destination</th>
        <th>Status</th>
        <th>Actions</th>
      </tr>
      <br />
    </thead>
    <tbody>
    {sampleData.map((row:any) => (
      <>
      <tr className="content-row">
        <td>{row.JobID}</td>
        <td>{row.PickupLocation}</td>
        <td>{row.Destination}</td>
        <td><span style={{backgroundColor:this.getCellBackgroudColor(row.Status),padding:'8px',borderRadius:'20px',fontWeight:'700',color:this.getCellTextColor(row.Status),fontSize:'12px'}}>{row.Status}</span></td>
        <td><span style={{backgroundColor:'#1D6FB8',padding:'8px',borderRadius:'13px',fontWeight:'400',color:'white',width:'100%',display:'inline-block'}}>{row.Actions}</span></td>
      </tr>
      <br />
      </>
    ))}
    </tbody>
  </table>
</div>
    </div>
    );
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { appointmentsList } = this.state;

    return (
      <div style={webstyle.AppointmentOuter} className='outerTabs'>
        <NavigationMenu navigation={this.props.navigation} id={""} />
        <Box style={{ paddingTop: "40px", display: 'flex', justifyContent: 'center' ,marginBottom:'30px'}}>
          <OuterContainer>
            {this.buttonTabs()}
            <ImageBox>
              <img src={appointment.default} alt="Right side image" style={{ width: '80%', height: 'auto' }} />
            </ImageBox>
          </OuterContainer>
         
        </Box>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center', marginBottom: '20px'
        }}>

          <img src={map.default} alt="Right side image" style={{ width: '95%', height: 'auto' }} />
        </div>
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const TableContainer = styled(Box)({
  overflowX: 'auto', // Allow horizontal scrolling
  marginTop: '20px', // Space above the table
});

const StyledTable = styled('table')({
  width: '100%',
  borderCollapse: 'collapse', // Prevent borders between cells
});

const HeaderRow = styled('tr')({
  backgroundColor: '#ECFDF5', // Light gray background for header
  borderRadius:'15px',
  clipPath: 'xywh(0 0 100% 100% round 1em)',
 
marginBottom:'20px'
});

const ContentRow = styled('tr')({
  
  // backgroundColor: '#fff', // White background for content rows
  // marginBottom: '10px', // Space between content rows
  // boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',

});

const TableHeaderCell = styled('th')({
  padding: '10px',
  textAlign: 'left',
  borderRadius: '8px', // Rounded corners for header cells
  

});

const TableCell = styled('td')({
  padding: '10px',
  border: 'none', // No borders for cells
  backgroundColor: '#fff', // Background color for cells
 
});

const OuterContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  width: '95%', // Outer container set to 70% width
});

const Tabbox = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "column", // Arrange items in a column
});

const Buttonbox = styled(Box)({
  width: "100%",
  height: "65px",
  display: "flex",
  paddingLeft: "10px",
  paddingRight: "10px",
  background: "#FFFFFF",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: "16px",
});

const ContentContainer = styled(Box)({
  display: "flex",
  flexDirection: "column", // Arrange content in a column
  marginTop: "20px", // Space between the tab bar and the content
});

const ImageBox = styled(Box)({
  flex: '0 0 30%', // Take up 30% width
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginLeft: '20px', // Space from the content
  overflow: 'hidden', // Prevent image overflow
  maxHeight: '100%', // Constrain image height
});

const ContentBox = styled(Box)({
  flex: 1, // Take up remaining space
  padding: '20px', // Optional padding
});
const Singlebuttonbox = styled(Box)({
  width: "130px",
  display: "flex",
  justifyContent: 'center',
  alignItems: "center",
});



const webstyle = {
  AppointmentOuter:{  margin: '0px', padding:'0px', width: '100vw', height: '100vh', },
  activeButoon: {
    width: "100%",
    background: "#ECFDF5",
    borderRadius: "50px",
    border:'1px solid #87C122',
    color: "#0F172A",
    height: "35px",
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "24px",
    textTransform: "capitalize" as "capitalize",
    whiteSpace: "nowrap"
  },

  notactiveButton: {
    color: "#475569",
    height: "35px",
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "24px",
    textTransform: "capitalize" as "capitalize",
    whiteSpace: "nowrap"
  },

  tabbox: {
    width: "100%",
    display: "flex",
    height: "100px"
  },

  buttonbox: {
    width: "70%",
    height: "72px",
    display: "flex",
    paddingLeft: "10px",
    paddingRight: "10px",
    background: "#FFFFFF",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "16px"
  },

  singlebuttonbox: {
    width: "355px",
    display: "flex",
    justifyContent: 'center',
    alignItems: "center"
  },

  choosedateimage: {
    width: "20px",
    height: "20px",
    paddingRight: "5px"
  }

}
// Customizable Area End
