import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";

export interface ApiCallFunction {
  contentType: string,
  method: string,
  endPoint: string,
  body?: {}
};

export interface ValidResponse {
  message: string,
};

export interface InValidResponse {
  error: string
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  currentStep: number;
  otp: string[];
  loader: boolean;
  secondsRemaining: number;
  isRunning: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

export default class TwoFactorAuthenticationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  genrateOtpApiCallId: string = "";
  verifyOtpApiCallId: string = "";
  intervalId: NodeJS.Timeout | null = null;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      currentStep: 1,
      otp: ['', '', '', ''],
      loader: false,
      secondsRemaining: 600,
      isRunning: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        this.callBackResponse(apiRequestCallId, responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  componentWillUnmount = async (): Promise<void> => {
    this.clearTimer();
  };

  apiCallFunction = async (data: ApiCallFunction) => {
    const { contentType, method, endPoint, body } = data;

    const header = {
      'Content-Type': contentType,
    }

    const requestMessage1 = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage1.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    )
    requestMessage1.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    )
    body &&
      requestMessage1.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )

    runEngine.sendMessage(requestMessage1.id, requestMessage1);
    return requestMessage1.messageId;
  };

  callBackResponse = (apiRequestCallID: string, responseJSON: ValidResponse & InValidResponse) => {
    if (this.isValidResponse(responseJSON)) {
      this.apiSuccessCallBacks(apiRequestCallID, responseJSON)
    };

    if (this.inValidResponse(responseJSON)) {
      this.apiErrorCallBacks(apiRequestCallID, responseJSON)
    };
  };

  isValidResponse = (responseJson: ValidResponse) => {
    return responseJson && responseJson.message;
  };

  inValidResponse = (errorReponse: InValidResponse) => {
    return errorReponse && errorReponse.error;
  };

  apiSuccessCallBacks = (apiRequestCallID: string, responseJSON: ValidResponse) => {
    if (apiRequestCallID == this.genrateOtpApiCallId) {
      this.handleStepOneSuccess();
    } else if (apiRequestCallID === this.verifyOtpApiCallId) {
      this.handleVerifySuccess();
    }
  };

  apiErrorCallBacks = (apiRequestCallID: string, responseJSON: InValidResponse) => {
    if (apiRequestCallID == this.verifyOtpApiCallId) {
      this.handleVerifyFailure(responseJSON.error);
    }
  };

  handleStepOne = async () => {
    let email = await getStorageData("email");
    const body = {
      email
    };

    this.genrateOtpApiCallId = await this.apiCallFunction({
      contentType: configJSON.validationApiContentType,
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.genrateOtpEndpoint,
      body: body
    });

    this.setState({ loader: true });
    this.resetTimer();
  };

  handleStepOneSuccess = () => {
    this.setState({ currentStep: 2, loader: false });
    this.startTimer();
  }

  handleChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const otp = [...this.state.otp];
    otp[index] = event.target.value;

    if (event.target.value.length === 1 && index < 3) {
      const nextInput = document.getElementById(`otp-${index + 1}`);
      nextInput?.focus();
    } else if (event.target.value.length === 0 && index > 0) {
      const prevInput = document.getElementById(`otp-${index - 1}`);
      prevInput?.focus();
    }
    this.setState({ otp });
  };

  handleVerify = async () => {
    let email = await getStorageData("email");
    const body = {
      email,
      otp: this.state.otp.join('')
    };

    this.verifyOtpApiCallId = await this.apiCallFunction({
      contentType: configJSON.validationApiContentType,
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.verifyOtpEndpoint,
      body: body
    });

    this.setState({ loader: true })
  };

  handleVerifySuccess = () => {
    this.setState({ currentStep: 5, loader: false });
  }

  handleVerifyFailure = (message: string) => {
    if (message == "Expired OTP") {
      this.setState({ currentStep: 4, loader: false });
    } else {
      this.setState({ currentStep: 3, loader: false });
    }
  };

  startTimer = () => {
    if (!this.state.isRunning) {
      this.setState({ isRunning: true });
      this.intervalId = setInterval(this.tick, 1000);
    }
  };

  clearTimer = () => {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  };

  tick = () => {
    this.setState((prevState) => {
      if (prevState.secondsRemaining <= 0) {
        this.clearTimer();
        return { secondsRemaining: 0, isRunning: false };
      }
      return {
        secondsRemaining: prevState.secondsRemaining - 1,
        isRunning: true,
      };
    });
  };

  resetTimer = () => {
    this.clearTimer();
    this.setState({ secondsRemaining: 600, isRunning: false });
  };

  formatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
  };


  handleGoBack = () => {
    if (this.state.currentStep >= 2) {
      this.setState({ currentStep: this.state.currentStep - 1 })
    }
  };

  handleTryAgain = () => {
    this.setState({ currentStep: 2, otp: ['', '', '', ''], })
  }

  hadleFinalStep = () => {
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "DriverSucessScreen");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  // Customizable Area End
}
