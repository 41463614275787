//Customizable Area Start
import React from "react";
import {
    Box,
    Typography,
    styled,
} from '@mui/material';
import SignUpRiderSuccessController, { configJSON } from "./SignUpRiderSuccessController.web";
import { mainLogo, success } from "./assets";
//Customizable Area End

// Customizable Area Start
// Customizable Area End

export default class SignUpRiderSuccess extends SignUpRiderSuccessController {
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        return (
            <BoxMain>
                <ImageBox>
                    <MainLogo src={mainLogo.default} alt="Success" />
                </ImageBox>
                <StyledImage src={success.default} alt="Success" />
                <TypographyAllSet>{configJSON.allSet}</TypographyAllSet>
                <TypographyGreatNews>{configJSON.greatNews}</TypographyGreatNews>
                <ButtonButton data-testId={"expolreId"} onClick={this.handleRedirect}>
                    <ButtonTypo>{configJSON.exploreride}</ButtonTypo>
                </ButtonButton>
            </BoxMain>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const BoxMain = styled(Box)({
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#ECFDF5',
    textAlign: 'center',
});

const ImageBox = styled(Box)({
    width: "100%",
    display: "inline-flex"
})

const MainLogo = styled('img')({
    width: '160px',
    height: '56px',
    marginLeft: "50px",
    "@media (max-width: 960px)": {
        display: "none"
    }
});

const StyledImage = styled('img')({
    height: '375px',
    "@media (max-width: 960px)": {
        height: '60%',
    }
});

const TypographyAllSet = styled(Typography)({
    marginTop: '20px',
    fontWeight: 700,
    fontSize: '35px',
    color: '#87C122',
    "@media (max-width: 960px)": {
        width: '100%',
        fontSize: '18px',
    }
});

const TypographyGreatNews = styled(Typography)({
    width: '490px',
    fontWeight: 400,
    fontSize: '24px',
    color: '#475569',
    "@media (max-width: 960px)": {
        width: '100%',
        fontSize: '18px',
    }
});

const ButtonButton = styled(Box)({
    width: '400px',
    height: '55px',
    marginTop: '15px',
    color: '#ffffff',
    backgroundColor: '#1D6FB8',
    cursor: "pointer",
    borderRadius: "12px",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
        backgroundColor: "#1D6FB8",
    },
    "@media (max-width: 960px)": {
        width: '70%',
        height: '40px',
    }
});
const ButtonTypo = styled(Typography)({
    fontSize:"20px",
    "@media (max-width: 960px)": {
        fontSize:"18px",
    }
})

// Customizable Area End
