export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgVisbility = require("../assets/visibility.svg");
export const imgVisbilityOff = require("../assets/visibilityOff.svg");
export const Mainlogo = require('../assets/logo.png');
export const sidebarOne = require('../assets/editProfile.png');
export const sidebarTwo = require('../assets/tripHistory.png');
export const sidebarThree = require('../assets/star.png');
export const sidebarFour = require('../assets/notificaiton.png');
export const sidebarFive = require('../assets/helpCenter.png');
export const sidebarSix = require('../assets/setting.png');