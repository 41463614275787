export const triangle = require("../assets/triangle.png");
export const progressBar = require("../assets/Circle.png");
export const settingIcon = require("../assets/settings.png");
export const progressIcon = require("../assets/Progress.png");
export const mailIcon = require("../assets/mail.png");
export const qrCodeIcon = require("../assets/qrcode.png");
export const payPalLogo = require("../assets/paypal.png");
export const rightArrow = require("../assets/Icon.png");
export const contact = require('../assets/contact.png')
export const tutorial = require('../assets/tutorial.png')
export const question = require('../assets/question.png')
export const thinking = require('../assets/thinking.png')
export const search = require('../assets/search.png')