// Customizable Area Start
import React from "react";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import {
    Box,
    Button,
    styled,
    IconButton,
    Typography,
    TextField
} from "@mui/material";
import { riderimage, thumpsdown, thumpsup } from "./assets";
import ReviewsRiderController, { Props } from "./ReviewsRiderController.web";
import { configJSON } from "./ReviewsController";


// Customizable Area End

// Customizable Area Start
const Reviewdriverbox = styled(Box)({
    flexDirection: "column" as "column",
    justifyContent: "center",
    width: "655px",
    display: "flex",
    gap: "20px",
    "@media (max-width: 680px)": {
        width: "90%",
    },
})

const DriverReviewInputGroup = styled(Box)({
    display: "flex",
    gap: "20px",
    justifyContent: "space-between",
    width:"100%"
});

const RateYourRiderText = styled(Box)({
    fontWeight: 400,
    lineHeight: "40px",
    fontSize: "30px",
    whiteSpace: "nowrap",
    color: "#1E293B",
    fontFamily: "Inter",

    "@media (max-width: 500px)": {
        fontSize: "25px",
    },
    "@media (max-width: 430px)": {
        fontSize: "20px",
    },
    "@media (max-width: 450px)": {
        fontSize: "18px",
    },
    "@media (max-width: 350px)": {
        fontSize: "16px",
    },
})


const Riderreviewimage = styled(Box)({
    width: "75%",
    display: 'flex',
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width: 518px)": {
        width: "100%",
    },
})


const Needhelptext = styled(Box)({
    fontFamily: "Inter",
    lineHeight: "40px",
    color: "#1D6FB8",
    fontSize: "30px",
    fontWeight: 400,
    "@media (max-width: 500px)": {
        fontSize: "27px",
    },
    "@media (max-width: 430px)": {
        fontSize: "22px",
    },
    "@media (max-width: 450px)": {
        fontSize: "25px",
    },
    "@media (max-width: 380px)": {
        fontSize: "20px",
    },
    "@media (max-width: 350px)": {
        fontSize: "16px",
    },
});


const InputWrapper = styled(Box)({
    position: "relative",
    width: "669px",
    zIndex: 2,
    marginTop: "50px"
});

const DriverReviewCustomLabel = styled(Typography)({
    position: "absolute",
    top: "-30px",
    backgroundColor: "white",
    padding: "0 4px",
    fontSize: "14px",
    fontWeight: 700,
    fontFamily: "Inter",
    lineHeight: "22px",
    zIndex: 1,
    marginBottom: "8px",
    color: "#334155",
    background: "transparent"
});

const Formbox = styled(Box)({
    width: "669px",
    paddingLeft: "10px",
    paddingRight: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "47px",
    borderRadius: "8px",
    border: '1px solid #1D6FB8',
    "@media (max-width: 710px)": {
        width:"100%"
    },
});


// Customizable Area End

export default class ReviewsRider extends ReviewsRiderController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {

        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <Box>
                <NavigationMenu navigation={this.props.navigation} id={""} />

                <Box style={webstyle.reviewridercontainer}>
                    <Box style={webstyle.reviewridertextbox}>
                        <RateYourRiderText>{configJSON.ridertext}</RateYourRiderText>
                    </Box>
                    <Reviewdriverbox >
                        <Box style={webstyle.reviewimagebox}>
                            <Riderreviewimage>
                                <img src={riderimage.default} style={{ width: "100%" }} />
                            </Riderreviewimage>
                            {!this.state.riderreviewform ? <Box display="flex">
                                {[1, 2, 3, 4, 5].map((starValue) => (
                                    <IconButton
                                        key={starValue}
                                        onClick={() => this.setState({ rating: starValue })}
                                        data-test-id = "starselectid"
                                    >
                                        {starValue <= (this.state.rating) ? (
                                            <StarIcon style={webstyle.selectedstarrating} />
                                        ) : (
                                            <StarBorderIcon style={webstyle.nonselectedstarrating} />
                                        )}
                                    </IconButton>
                                ))}
                            </Box> : <>  <Needhelptext>{configJSON.triptext}</Needhelptext>
                                {this.state.form.map((item, index) => <Formbox key={index} >
                                    <Typography style={webstyle.formtext}>{item.formtext}</Typography>
                                    <Box style={webstyle.thumsupimagebox}>
                                        <Box>
                                            <img src={thumpsup.default} />
                                        </Box>
                                        <Box>
                                            <img src={thumpsdown.default} />
                                        </Box>
                                    </Box>
                                </Formbox>)}
                                <DriverReviewInputGroup>
                                    <InputWrapper>
                                        <DriverReviewCustomLabel>{configJSON.morecomments}</DriverReviewCustomLabel>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            placeholder="Let us know what went well, or what we can improve on!"
                                            type="text"
                                            InputLabelProps={{ shrink: true }}
                                            name="Message"
                                            multiline
                                            minRows={5}
                                            InputProps={{
                                                style: {
                                                    borderRadius: '8px',
                                                    background: "white",
                                                },
                                                inputProps: {
                                                    style: {
                                                        color: "#0F172A",
                                                        fontSize: '14px',
                                                        fontWeight: 400,
                                                    },
                                                }
                                            }}
                                            sx={{
                                                "& .MuiInputBase-input::placeholder": {
                                                    color: "#94A3B8", fontFamily: "Inter", fontSize: "16px", fontWeight: 400
                                                },
                                            }}
                                        />
                                    </InputWrapper>
                                </DriverReviewInputGroup>
                            </>}
                        </Box>
                        <Box>
                        </Box>
                        <Box style={webstyle.RiderReviewbuttonboxcontainer}>
                            <Box style={webstyle.riderreviewbuttonbox}>
                                <Box style={webstyle.ridersubmitbuttonbox} >
                                    <Button style={webstyle.ridersubmittextcolor} onClick={() => this.setState({ riderreviewform: true })}>{configJSON.submittext}</Button>
                                </Box>
                                <Box style={webstyle.ridercancelbuttonbox} onClick={this.cancelReview} data-test-id="cancelreview"> 
                                    <Button style={webstyle.ridercanceltextcolor} >{configJSON.canceltext}</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Reviewdriverbox>
                </Box>
            </Box>
            // Customizable Area End

        );
    }
}

// Customizable Area Start

const webstyle = {

    reviewridercontainer: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        paddingTop: "10px",
        paddingBottom: "10px",
        justifyContent: "center",
        flexDirection: "column" as "column",
    },

    formbox: {
        width: "669px",
        paddingLeft: "10px",
        paddingRight: "10px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "47px",
        borderRadius: "8px",
        border: '1px solid #1D6FB8'
    },

    formtext: {
        fontFamily: "Inter",
        lineHeight: "22px",
        color: "#1D6FB8",
        fontSize: "14px",
        fontWeight: 400,
    },

    thumsupimagebox: {
        display: "flex",
        alignItems: "center",
        gap: "8px"
    },

    RiderReviewbuttonboxcontainer: {
        width: "100%",
        display: "flex",
        justifyContent: "center"
    },

    selectedstarrating: {
        color: '#87C122',
        width: "45.64px",
        height: "45.64px"
    },
    nonselectedstarrating: {
        color: '#0F172A',
        width: "45.64px",
        height: "45.64px"
    },


    riderreviewbuttonbox: {
        width: "70%",
        flexDirection: "column" as "column",
        display: "flex",
        gap: '10px'
    },

    reviewimagebox: {
        display: "flex",
        marginTop: "10px",
        gap: "20px",
        flexDirection: "column" as "column",
        alignItems: 'center'
    },

    reviewridertextbox: {
        textAlign: "center" as "center",
        height: "40px",
        borderRadius: "5px",
    },

    ridersubmitbuttonbox: {
        background: "#1D6FB8",
        width: "100%",
        display: "flex",
        alignItems: "center",
        borderRadius: "8px",
        height: "56px",
        justifyContent: "center",
        color: "black",
    },

    ridercancelbuttonbox: {
        background: "",
        color: "black",
        width: "100%", justifyContent: "center",
        alignItems: "center",
        height: "56px",
        display: "flex",
        border: "1px solid  #1D6FB8",
        borderRadius: "8px",
    },

    ridersubmittextcolor: {
        color: "#ECFDF5",
        fontFamily: "Inter",
        textTransform: "capitalize" as "capitalize",
        fontSize: "20px",
        fontWeight: 400,
        lineHeight: "28px",
    },


    ridercanceltextcolor: {
        fontFamily: "Inter",
        fontSize: "20px",
        fontWeight: 400,
        lineHeight: "28px",
        color: "#1D6FB8",
        textTransform: "capitalize" as "capitalize",
    },

}
// Customizable Area End