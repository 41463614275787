// Customizable Area Start
import React from "react";
import UpcomingRidesController, { Props } from "./UpcomingRidesController.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export default class UpcomingRides extends UpcomingRidesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {

        // Customizable Area Start
        // Customizable Area End
        return (
            // Customizable Area Start
            <h1>UpcomingRide</h1>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
// Customizable Area End
