// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    navigation: any;
    id: string;
    children?: React.ReactNode;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    isSidebarOpen: boolean;
    selectedSection: string;
    idUserRole:string;
    uploadImageState: boolean;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class SideMenuController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            isSidebarOpen: true,
            selectedSection: 'Edit Profile',
            idUserRole:"",
            uploadImageState:false
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End


    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        this.handleRoleMenu();
        // Customizable Area End
    }

    // Customizable Area Start
    handleSectionChange = (section: string) => {
        const isMobile = window.innerWidth <= 960;
        this.setState({
            selectedSection: section,
            isSidebarOpen: isMobile ? false : this.state.isSidebarOpen,
        });
    };

    handleRoleMenu = async () => {
        let idUserRole = await getStorageData("role_id");
        this.setState({ idUserRole })
    };

    handleToggleSidebar = () => {
        this.setState({ isSidebarOpen: !this.state.isSidebarOpen });
    };

    handleUploadImage = () => {
        this.setState({ uploadImageState: !this.state.uploadImageState })
    }

    // Customizable Area End
}
