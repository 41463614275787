Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ProjectTemplates";
exports.labelBodyText = "ProjectTemplates Body";
exports.projectAPiEndPoint = "bx_block_projecttemplates/project_templates";
exports.pickuptext = "Pickup time"
exports.btnExampleTitle = "CLICK ME";
exports.Start = "Start";
exports.Destination = "Destination";
exports.FavoritePlaces = "Favorite Places";
exports.ChooseFromMap = "Choose from Map";
exports.FrequentlyUsed = "Frequently Used";
exports.CarType = "Car Type";
exports.sucessMessage = "Your ride is all set!";
exports.Sucessdescription = "Your booking has been confirmed.";
exports.Sucessdescription2 = "Driver will pickup you in 2 minutes.";
exports.LocationError = "Sorry! We don't cover this location right now.";
exports.ErrorText2 = "Please check your internet connection";
// Customizable Area End