
// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    map: any;
    showChat:boolean;
    // Customizable Area End
}

interface SS {
    id: any;
}
export default class ChooseFromMapController extends BlockComponent<Props, S, SS> {
   
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            
           
        ];
        this.state = {
            map: [],
            showChat:true,
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        // Customizable Area End
    }
    async receive(from: string, message: Message) {
        // Customizable Area Start

        runEngine.debugLog("Message Recieved", JSON.stringify(message));


        // Customizable Area End
    }

    // Customizable Area Start
    closeChat=()=>{
        this.setState({showChat:false})
    }
    navigateToProjectTemplate=()=>{ 
            const navigate: Message = new Message(getName(MessageEnum.NavigationMessage));
              navigate.addData(getName(MessageEnum.NavigationTargetMessage), "ProjectTemplates");
              navigate.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
              this.send(navigate);
    }
    // Customizable Area End
}