
// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}
type Text = {
    formtext: string;
};
interface S {
    // Customizable Area Start
    riderreviewform: boolean;
    rating: number
    form: Text[]
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class ReviewsRiderController extends BlockComponent<Props, S, SS> {
    getHelpCentreApiCallId: any;
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];
        this.state = {
            riderreviewform: false,
            rating: 0,
            form: [{ formtext: "How was your driver?" },
            { formtext: "How was the car?" },
            { formtext: "How was the route?" },
            { formtext: "How was the payment?" }]
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        // Customizable Area End
    }


    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recieved", JSON.stringify(message));


        // Customizable Area End
    }

    // Customizable Area Start
    cancelReview = ()=>{
        if(this.state.riderreviewform){
            this.setState({riderreviewform:false})
        }else {
            const navigatetodashboard: Message = new Message(getName(MessageEnum.NavigationMessage));
            navigatetodashboard.addData(getName(MessageEnum.NavigationTargetMessage), 'SideMenuBasic');
            navigatetodashboard.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(navigatetodashboard)
        }
    }
    // Customizable Area End
}
