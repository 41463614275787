import React from "react";

// Customizable Area Start
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  Typography,
  IconButton,
  styled,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SecurityIcon from "@mui/icons-material/Security";
import CallIcon from "@mui/icons-material/Call";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
// Customizable Area End

import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller";

export default class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <BoxMain>
        <ListRight>

          <ListItemTextRight>
            <ListItemIconRightText>
              <CommonIcon as={EditIcon} />
            </ListItemIconRightText>
            <Typography>{configJSON.changePassword}</Typography>
            <IconButtonRight>
              <ArrowForwardIcon />
            </IconButtonRight>
          </ListItemTextRight>

          <ListItemTextRight>
            <ListItemIconRightText>
              <CommonIcon as={LocationOnOutlinedIcon} />
            </ListItemIconRightText>
            <Typography>{configJSON.homeAddress}</Typography>
            <IconButtonRight>
              <ArrowForwardIcon />
            </IconButtonRight>
          </ListItemTextRight>

          <ListItemTextRight>
            <ListItemIconRightText>
              <CommonIcon as={SecurityIcon} />
            </ListItemIconRightText>
            <Typography>{configJSON.privacyAndPolicy}</Typography>
            <IconButtonRight>
              <ArrowForwardIcon />
            </IconButtonRight>
          </ListItemTextRight>

          <ListItemTextRight>
            <ListItemIconRightText>
              <CommonIcon as={CallIcon} />
            </ListItemIconRightText>
            <Typography>{configJSON.contactUs}</Typography>
            <IconButtonRight>
              <ArrowForwardIcon />
            </IconButtonRight>
          </ListItemTextRight>

          <ListItemTextRight>
            <ListItemIconRightText>
              <CommonIcon as={ExitToAppIcon} />
            </ListItemIconRightText>
            <Typography data-testId={"logoutID"} onClick={this.handleLogout}>{configJSON.logOut}</Typography>
            <IconButtonRight>
              <ArrowForwardIcon />
            </IconButtonRight>
          </ListItemTextRight>
        </ListRight>
      </BoxMain>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const ArrowForwardIcon = styled(ArrowForwardIosIcon)({
  color: "#0F172A",
  height: "15px",
});

const BoxMain = styled(Box)({
  minHeight: "100vh",
  backgroundColor: "#ECFDF5",
  "@media (max-width: 960px)": {
    minHeight: "auto", 
  },
});

const IconButtonRight = styled(IconButton)({
  marginLeft: "auto",
});

const CommonIcon = styled("svg")({
  color: "#0F172A",
});

const ListRight = styled(List)({
  width: "95%",
  background: "#FFFFFF",
  color: "#94A3B8",
  borderRadius: "1rem",
  padding: "10px",
  "@media (max-width: 960px)": {
    width: "100%",
  }
});

const ListItemIconRightText = styled(ListItemIcon)({
  minWidth: "37px",
  cursor: 'pointer !important'

});

const ListItemTextRight = styled(ListItem)({
  padding: "27px 10px",
  cursor: 'pointer !important', 
  pointerEvents: 'auto',
});

// Customizable Area End
