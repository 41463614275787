import React, { Component } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Box, Typography, Button, LinearProgress ,styled} from '@mui/material';
import {Logo} from '../../blocks/email-account-registration/src/assets'
// Styled components
const LogoContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  textAlign: 'center',
});

const LogoTextBottom1 = styled('div')({
  marginTop: '16px',
  fontWeight: 400,
  fontSize: '18px',
  color: 'white',
});

const RoadText = styled(Typography)({
  color: 'white',
  fontWeight: 400,
  fontSize: '30px',
  marginTop: '40px',
});

const ProgressBarContainer = styled('div')({
  width: '100%',
  margin: '20px 0',
});

const Heading = styled(Typography)({
  fontWeight: 400,
  fontSize: '30px',
  color: '#1D6FB8',
  width: '100%',
  marginBottom: '16px',
});

const CustomLinearProgress = styled(LinearProgress)({
  height: '10px',
  borderRadius: '5px',
  backgroundColor: '#E2E8F0',
  '& .MuiLinearProgress-bar': {
    backgroundColor: '#4caf50',
  },
});

// Validation schema
const validationSchema4 = Yup.object().shape({
  AcchName: Yup.string()
    .required('Account Holder Name is required')
    .matches(/^[a-zA-Z\s]+$/, 'Account Holder Name must only contain letters'),

  RNum: Yup.string()
    .required('Routing Number is required')
    .max(15, 'Routing Number cannot be more than 15 digits')
    .matches(/^\d+$/, 'Routing Number must only contain numbers'),

  AccNum: Yup.string()
    .required('Account Number is required')
    .min(8, 'Account Number must be at least 8 digits')
    .max(20, 'Account Number cannot be more than 20 digits')
    .matches(/^\d+$/, 'Account Number must only contain numbers'),

  ConAccNum: Yup.string()
    .required('Confirm Account Number is required')
    .oneOf([Yup.ref('AccNum')], 'Confirm Account Number must match Account Number'),
});
const webStyle = {
  InnerFirst:{color:'#1D6FB8',fontSize:'20px',fontWeight:400,marginBottom:'15px'},
    ButtonStyle3:{
      fontSize: '16px',
      fontWeight: 400,
      borderRadius: '8px',
      border: '2px solid #CBD5E1',
      marginBottom: '10px',
      textTransform: 'none',
      width: '100%',
      height: '56px',
      padding: '0 16px',
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
 ErrorMessage:{ color:'#DC2626', fontSize: '12px',fontWeight:'400', marginTop: '5px',marginBottom:'5px' },
 FieldHeader:{
  fontSize: "14px",
  fontWeight: 700,
  paddingBottom:'5px',
MarginTop:'5px'
 },
 ButtonStyle:{ height: '56px', backgroundColor: '#1D6FB8', color: '#ffffff', borderRadius: '8px', fontSize: '20px', lineHeight: '20px',fontWeight:'400',marginBottom:'20px' ,textTransform: 'none' as React.CSSProperties['textTransform']},
 ButtonStyle2:{ height: '56px', backgroundColor: '#ffffff', color: '#1D6FB8', borderRadius: '8px', fontSize: '20px', lineHeight: '20px',fontWeight:'400',marginBottom:'10px', border: '1px solid #1D6FB8' ,textTransform: 'none' as React.CSSProperties['textTransform'] },
 marginBottomm4:{ marginBottom: "15px" },
 InputFIeldStyle4:{width:'100%',height:'44px',borderRadius:'8px'},
   
  };
interface FormValues {
  AcchName: string;
  RNum: string;
  AccNum: string;
  ConAccNum: string;
}

interface Form3Props {
  Submit1: (values: FormValues) => void;
  initialValues: FormValues;
  back:() => void;
}

interface Form3State {
  isFocusAcchName: boolean;
  isFocusRNum: boolean;
  isFocusAccNum: boolean;
  isFocusConAccNum: boolean;
}

class Form3 extends Component<Form3Props, Form3State> {
  constructor(props: Form3Props) {
    super(props);
    this.state = {
      isFocusAcchName: false,
      isFocusRNum: false,
      isFocusAccNum: false,
      isFocusConAccNum: false,
    };
  }

  render() {
    const { Submit1, initialValues ,back} = this.props;

    return (
        <Box
        style={{
          display: "flex",
          height: "100vh",
          fontFamily: "'Poppins', sans-serif",
          fontWeight: 600,
          marginBottom: "0px",
          width: "100vw",
        }}
      >
        <div className="left-half">

          <Formik initialValues={initialValues} validationSchema={validationSchema4} onSubmit={(values) => {
            Submit1(values)
          }}>
            {(props) => {
              return (<Form translate={undefined} className="custom-style">
                <Heading data-testid="signup-heading1">
                  Create your E-Ryde account
                </Heading>

                <ProgressBarContainer>
                  <Typography variant="body2" style={{ fontWeight: 400, fontSize: "18px", color: "1D6FB8", marginBottom: "8px" }} data-testid="progress-step">
                    Step 4 of 4
                  </Typography>
                  <CustomLinearProgress variant="determinate" value={100} data-testid="progress-bar" />
                </ProgressBarContainer>
                <br />
                <Box style={webStyle.marginBottomm4}>
                  <Typography style={webStyle.FieldHeader}>
                  Account holder name
                  </Typography>

                  <input
                    id="AcchName"
                    name="AcchName"
                    data-test-id="AcchName"
                    placeholder="Your name"
                    value={props.values.AcchName}
                    type="text"
                    onChange={(e) => {
                      props.setFieldValue("AcchName", e.target.value); // Update Formik's field value
                    }}
                    className={this.state.isFocusAcchName ? 'input-focused' : 'input-default'}
                    onFocus={() => this.setState({ isFocusAcchName: true })}
                    onBlur={() => this.setState({ isFocusAcchName: true })}
                    style={{
                      ...webStyle.InputFIeldStyle4,
                      border: props.errors.AcchName && props.touched.AcchName ? '1px solid #F87171' : '1px solid #CBD5E1'
                    }}
                  />
                  {props.errors.AcchName && props.touched.AcchName && (
                    <div style={webStyle.ErrorMessage}>
                      <ErrorMessage name="AcchName" />
                    </div>
                  )}
                </Box>
                <Box style={webStyle.marginBottomm4}>
                  <Typography style={webStyle.FieldHeader}>
                  Routing number
                  </Typography>
                  <input
                    id="RNum"
                    name="RNum"
                    data-test-id="RNum"
                    placeholder="1111"
                    value={props.values.RNum}
                    type="text"
                    onChange={(e) => {
                      props.setFieldValue("RNum", e.target.value); // Update Formik's field value
                    }}
                    className={this.state.isFocusRNum ? 'input-focused' : 'input-default'}
                    onFocus={() => this.setState({ isFocusRNum: true })}
                    onBlur={() => this.setState({ isFocusRNum: true })}
                    style={{
                      ...webStyle.InputFIeldStyle4,
                      border: props.errors.RNum && props.touched.RNum ? '1px solid #F87171' : '1px solid #CBD5E1'
                    }}
                  />
                  {props.errors.RNum && props.touched.RNum && (
                    <div style={webStyle.ErrorMessage}>
                      <ErrorMessage name="RNum" />
                    </div>
                  )}

                </Box>
                <Box style={webStyle.marginBottomm4}>
                  <Typography style={webStyle.FieldHeader}>
                  Account number
                  </Typography>

                  <input
                    id="AccNum"
                    name="AccNum"
                    value={props.values.AccNum}
                    data-test-id="AccNum"
                    placeholder="90210"
                    type="text"
                    onChange={(e) => {
                      props.setFieldValue("AccNum", e.target.value); // Update Formik's field value
                    }}
                    className={this.state.isFocusAccNum ? 'input-focused' : 'input-default'}
                    onFocus={() => this.setState({ isFocusAccNum: true })}
                    onBlur={() => this.setState({ isFocusAccNum: true })}
                    style={{
                      ...webStyle.InputFIeldStyle4,
                      border: props.errors.AccNum && props.touched.AccNum ? '1px solid #F87171' : '1px solid #CBD5E1'
                    }}
                  />
                  {props.errors.AccNum && props.touched.AccNum && (
                    <div style={webStyle.ErrorMessage}>
                      <ErrorMessage name="AccNum" />
                    </div>
                  )}

                </Box>
                <Box style={webStyle.marginBottomm4}>
                  <Typography style={webStyle.FieldHeader}>
                  Confirm Account number
                  </Typography>

                  <input
                    id="ConAccNum"
                    name="ConAccNum"
                    data-test-id="ConAccNum"
                    value={props.values.ConAccNum}
                    placeholder="90210"
                    type="text"
                    onChange={(e) => {
                      props.setFieldValue("ConAccNum", e.target.value); // Update Formik's field value
                    }}
                    className={this.state.isFocusConAccNum ? 'input-focused' : 'input-default'}
                    onFocus={() => this.setState({ isFocusConAccNum: true })}
                    onBlur={() => this.setState({ isFocusConAccNum: true })}
                    style={{
                      ...webStyle.InputFIeldStyle4,
                      border: props.errors.ConAccNum && props.touched.ConAccNum ? '1px solid #F87171' : '1px solid #CBD5E1'
                    }}
                  />
                  {props.errors.ConAccNum && props.touched.ConAccNum && (
                    <div style={webStyle.ErrorMessage}>
                      <ErrorMessage name="ConAccNum" />
                    </div>
                  )}

               </Box>
                <br />
                <Button color="primary" variant="contained" style={webStyle.ButtonStyle} data-test-id='LogIn4' fullWidth
                  type="submit">Continue</Button>
                <Button color="primary" variant="contained" style={webStyle.ButtonStyle2} data-test-id='back3' fullWidth size="large"
                 onClick={back}>Back</Button>
              
              </Form>)
            }}

          </Formik>

        </div>

        <div className="right-half">
          <div style={{backgroundColor:'#ECFDF5',
          height:'85%',
            width:'100%'
            }}>
        <img src={Logo.default} alt="Logo" />
        </div>
         <div className="outer-div">
         <div style={{...webStyle.InnerFirst,marginBottom:'15px'}}>
         Earn on the Road, Drive with Freedom
         </div>
         <div style={{...webStyle.InnerFirst}}>
         Non-medical transport
         </div>
         </div>
        </div>
      </Box>
    );
  }
}

export default Form3;
