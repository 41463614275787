export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const backarrow = require("../assets/backarrow.png");
export const cardblank = require("../assets/cardblank.png");
export const fillstar = require("../assets/fillstar.png");
export const empty = require("../assets/empty.png");
export const freq = require("../assets/freq.png");
export const expand = require("../assets/expand.png");
export const location = require("../assets/location.png");
export const search = require("../assets/search.png");
export const search2 = require("../assets/search2.png");
export const collapse = require("../assets/collapse.png");
export const send = require("../assets/send.png");
export const choseedate = require('../assets/choosedate.png');
export const map = require('../assets/map.png');
export const home = require('../assets/home.png');
export const work = require('../assets/work.png');
export const pickdate = require("../assets/pickdate.png");
export const timer = require ("../assets/timer.png");
export const carImage = require ("../assets/carImage.png");
export const orderSummery = require ("../assets/orderSummery.png");
export const bro1 = require ("../assets/bro(1).png");
export const pickdrop = require ("../assets/pickdrop.png");
export const avatar = require ("../assets/avatar.png");
export const summery = require ("../assets/summery.png");
export const sucessDialog = require ("../assets/bro.png")
export const amber = require ("../assets/amber.png");

