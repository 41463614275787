Object.defineProperty(exports, "__esModule", {
    value: true
  });

//APi Methods
exports.httpGetMethod = "GET";
exports.httpPostMethod =  "POST";

exports.profileValidationSettingsAPiEndPoint = "profile/validations";
exports.passwordRecoveryStartOtpAPiEndPoint = "forgot_password/otp"
exports.passwordRecoveryConfirmOtpAPiEndPoint = "otp_confirmation"
exports.passwordRecoveryChangePasswordAPiEndPoint = "forgot_password/password"
exports.forgotPasswordAPiContentType = "application/json";
exports.pleaseEnterAValidEmail = "Please enter a valid email";
exports.emailIsRequired = "Email is required";
exports.phoneNumberIsNotValid = "Phone number is not valid";
exports.phoneNumberIsRequired = "Phone number is required";
exports.otpCodeIsRequired = "Phone number is required";
exports.pleaseEnterAPassword = "Please enter a password";
exports.passwordMustBeAtLeast2Characters = "Password must be at least 2 characters";
exports.pleaseConfirmYourPassword = "Please confirm your password";
exports.passwordsMustMatch = "Passwords must match";
exports.invalidEmailAddress = "Invalid email address";
exports.invalidPassword = "Invalid password";
exports.goToOtpAfterPhoneValidationErrorTitle = "Error";
exports.goToOtpAfterPhoneValidationErrorBody = "Please select country code";

exports.labelTextIsAccountRecovery = "Account Recovery";
exports.secondLabelText = "Please choose what type of account you signed up with."
exports.thirdLabelText = "To Reset your password, please enter the email associated with your account.";
exports.forthLabelText = "We sent a confirmation code to the following email:";
exports.fifthLabelText = "To Reset your password, please enter the phone number associated with your account."
exports.sixthLabelText = "We sent a confirmation code to the following phone:"

exports.firstInputAutoCompleteType = "email";
exports.firstInputPlaceholder = "Email";
exports.firstInputKeyboardStyle = "email-address";
exports.firstInputErrorColor = "red";

exports.buttonTextIsNext = "Next";
exports.buttonColorForNextButton = "#6200EE";

exports.secondInputAutoCompleteType = "tel";
exports.secondInputKeyboardType= "phone-pad"
exports.secondInputPlaceholder = "Mobile"
exports.secondInputErrorColor = "red";

exports.thirdInputPlaceholder = "Enter OTP";
exports.thirdInputErrorColor = "red";

exports.buttonTitleIsSMSPhoneAccount = "SMS (Phone) Account";
exports.buttonTitleIsEmailAccount = "Email Account";

exports.labelTextIsPleaseEnterYourNewPassword = "Please enter your new password.";
exports.labelTextIsYourPasswordHasBeenSuccessfullyChanged = "Your password has been successfully changed";

exports.handled = "handled";

exports.placeholderIsReTypePassword = "Re-Type Password";

exports.buttonTitleIsOk = "Ok"
exports.buttonColorForOkButton = "#6200EE"; 

exports.placeholderIsPassword = "password";
exports.countryCodeSelectorPlaceholder = "Select Country";
// Customizable Area Start
exports.phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
exports.back = "Back to";
exports.signin = "Sign in";
exports.sendMail = "Send a recovery link";
exports.linkSend = "The recovery link will expire after 1 hour";
exports.yourSafeText = "Your Safe and smooth ride awaits.";
exports.nonMedical = "Non-Medical Transport";
exports.forgotPassword = "Forgot password?";
exports.noWorries = "No worries, we’ll send a recovery link to your email";
exports.emailAddress = "Email Address";
exports.emailPlaceholder = "@Email Address";
exports.checkYourEmail = 'Check your email ';
exports.checkResetLinkOne = 'We have sent a password reset link to your email';
exports.resendEmail = 'Resend email';
exports.imgTextOne = 'Your safe and smooth ride awaits.';
exports.setNewPass = 'Set New Password';
exports.inputYourNewPass = 'Your new password';
exports.inputYourNewConfirmPass = 'Confirm your new password';
exports.labelYourNewPass = 'New Password';
exports.labelYourNewConfirmPass = 'Confirm New Password';
exports.enterNewPass = 'Enter a new password for ';
exports.paswordMustContain = 'Your password must contain';
exports.atleastOneCapital = 'At least one capital letter';
exports.atleastOneLower = 'At least one lowercase letter';
exports.atleastOneNumber = 'At least one number';
exports.minimumChar = 'Minimum character length is 8 characters';
exports.reserPasswordEndpoint = "bx_block_forgot_password/passwords/request_reset";
exports.reserPasswordUpdateEndpoint = "bx_block_forgot_password/passwords/update_password";
exports.httpPutMethod =  "PUT";
exports.hasUpperCase = 'At least one capital letter';
exports.hasLowerCase = 'At least one lowercase letter';
exports.hasNumber = 'At least one number';
exports.hasSpecialChar = 'At least one special character';
// Customizable Area End