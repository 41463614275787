
// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    editdata:{}
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    helpCentreFaq: any;
    option: any
    ModalOpen: boolean
    drivereditstep: number
    vehicletypemodal: any
    vehicletype: any
    vehicletypeselected:any
       documentData:{
        licenceNumber:number | undefined
        insaurenceNumber: number | undefined
        insaurenceDocument :string
        licencePicture : string
        dmvdocument: string | undefined
        requirement:string
    }
    carDetailsdata:{
        vehicletype:string
        VehicleMake:string
        vehiclemodel:string
        vehicleyear:string
        numberofSeats:string
        VehicleLicenceplate:string
    }
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class UserProfileDriverController extends BlockComponent<Props, S, SS> {
    getHelpCentreApiCallId: any;
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];
        this.state = {
            helpCentreFaq: [],
            option: ["None", "Wheelchair", "Gurney"],
            ModalOpen: false,
            drivereditstep: 2,
            vehicletypemodal: false,
            vehicletype: ["Sedan", "Station wagon", "Hatchback", "SUV", "Minivan", "Other"],
            vehicletypeselected:"",
            documentData:{
                licenceNumber:undefined,
                insaurenceNumber : undefined,
                insaurenceDocument:"",
                licencePicture:"",
                dmvdocument: "",
                requirement:""
            },
            carDetailsdata:{
                vehicletype:"",
                VehicleMake:"",
                vehiclemodel:"",
                vehicleyear:"",
                numberofSeats:"",
                VehicleLicenceplate:""
            }
           
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        console.log(this.props,"props")
        // Customizable Area End
    }


    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recieved", JSON.stringify(message));


        // Customizable Area End
    }

    // Customizable Area Start
    handleDialogClose2 = () => {
        this.setState({ ModalOpen: false })
    }
    handlevehicleModalclose = () => {
        this.setState({ vehicletypemodal: false })
    }
    handlechange = (event: any) => {
        const { name, files, value } = event.target;
        if (files && files.length > 0) {
            let fileName = files[0].name; 
            this.setState(prevState => ({
                documentData: {
                    ...prevState.documentData,
                    [name]: fileName.trim()
                }
            }));
        }
        else {
            this.setState(prevState => ({
                documentData: {
                    ...prevState.documentData,
                    [name]: value
                },
                carDetailsdata: {
                    ...prevState.carDetailsdata,
                    [name]: value
                }
            }));
        }
    }
    // Customizable Area End
}
