// Customizable Area Start
import React from "react";
import { configJSON, Props } from "./HelpCentreController";
import HelpCentreController from "./HelpCentreController";
import { Box, Typography, Button, styled, TextField } from "@mui/material"
import { contact, question, search, tutorial } from "./assets";
import HelpCentreFaq from "./HelpCentreFaq.web";
// Customizable Area End

// Customizable Area Start
const ContactusInputGroup = styled(Box)({
  display: "flex",
  gap: "20px",
  "@media (max-width: 726px)": {
    width: "100%",
  },
});

const InputWrapper = styled(Box)({
  position: "relative",
  marginBottom: "24px",
  zIndex: 2,
  "@media (max-width: 726px)": {
    width: "80%",
  },
});

const ContactusCustomLabel = styled(Typography)({
  position: "absolute",
  top: "-45px",
  backgroundColor: "white",
  padding: "0 4px",
  fontSize: "20px",
  fontWeight: 700,
  fontFamily: "Inter",
  lineHeight: "28px",
  zIndex: 1,
  marginBottom: "8px",
  color: "#334155",
  background: "transparent",
  whiteSpace: "nowrap"
});

const Helpboxcontainer = styled(Typography)({
  marginTop: "50px",
  paddingBottom: "20px"
});

const Helpcenteroptionboxcontainer = styled(Box)({
  width: "70%",
  display: "flex",
  gap: "24px",
  alignItems: "center",
  "@media (max-width: 1150px)": {
    width: "80%",
  },
  "@media (max-width: 900px)": {
    width: "100%",
  },
  "@media (max-width: 600px)": {
    flexDirection: "column"
  },
});

const ContactelpcenterOptionbox = styled(Box)({
  width: "200px",
  height: "200px",
  border: "1px solid #E2E8F0",
  borderRadius: "16px",
  display: "flex",
  flexDirection: "column" as "column",
  alignItems: "center",
  justifyContent: "center",
  background: "#CBD5E1",
  "@media (max-width: 600px)": {
    width: "100%",
  },
});

const FaqhelpcenterOptionbox = styled(Box)({
  width: "200px",
  height: "200px",
  border: "1px solid #E2E8F0",
  alignItems: "center",
  borderRadius: "16px",
  display: "flex",
  flexDirection: "column" as "column",
  justifyContent: "center",
  background: "#F59E0B",
  "@media (max-width: 600px)": {
    width: "100%",
  },
});

const TutorialshelpcenterOptionbox = styled(Box)({
  width: "200px",
  height: "200px",
  border: "1px solid #E2E8F0",
  alignItems: "center",
  borderRadius: "16px",
  display: "flex",
  flexDirection: "column" as "column",
  justifyContent: "center",
  background: "#BFDBFE",
  "@media (max-width: 600px)": {
    width: "100%",
  },
});

// Customizable Area End

export default class HelpCentre extends HelpCentreController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        {!this.state.showqueanspage ? <><Box>
          <Helpboxcontainer >
            <ContactusInputGroup>
              <InputWrapper>
                <ContactusCustomLabel>{configJSON.helpText}</ContactusCustomLabel>
                <TextField
                  variant="outlined"
                  style={{ borderRadius: "8px" }}
                  placeholder="Search help"
                  InputLabelProps={{ shrink: true }}
                  data-test-id="contact-us-name"
                  name="name"
                  fullWidth
                  InputProps={{
                    sx: {
                      borderRadius: '8px',
                      background: "white",
                      width: "539px",
                      height: "41px",
                      paddingLeft:"30px",
                      fontFamily:"Inter",
                      fontWeight:400,
                      lineHeight:"24px",
                      color:"#94A3B8",
                      "@media (max-width: 726px)": {
                        width: "100%",
                      },
                    },
                  }}
                />
                <img src={search.default} style={webstyle.searchhelpimage}/>
              </InputWrapper>
              <Button style={webstyle.searchbutton}>{configJSON.searchText}</Button>
            </ContactusInputGroup>
            <Helpcenteroptionboxcontainer>
              <ContactelpcenterOptionbox onClick={() => this.handleNavigation("Contactus")} data-test-id="contactredirectid">
                <img src={contact.default} />
                <Typography>{configJSON.contactText}</Typography>
              </ContactelpcenterOptionbox>
              <FaqhelpcenterOptionbox onClick={() => this.handleNavigation("Faq")} data-test-id="getqueansid">
                <img src={question.default} />
                <Typography>{configJSON.faqText}</Typography>
              </FaqhelpcenterOptionbox>
              <TutorialshelpcenterOptionbox>
                <img src={tutorial.default} />
                <Typography>{configJSON.tutorials}</Typography>
              </TutorialshelpcenterOptionbox>
            </Helpcenteroptionboxcontainer>
          </Helpboxcontainer>

        </Box></> : <HelpCentreFaq navigation={undefined} id={""} helpcenterQA={this.state.helpCentreQA}/>}
      </>


      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webstyle = {
  helpcenterOptionbox: {
    width: "200px",
    height: "200px",
    border: "1px solid #E2E8F0",
    borderRadius: "16px",
    display: "flex",
    flexDirection: "column" as "column",
    alignItems: "center",
    justifyContent: "center"
  },

  searchbutton: {
    width: "110px",
    height: "41px",
    background: "#1D6FB8",
    color: "white",
    textTransform: 'capitalize' as 'capitalize',
    borderRadius: "8px"
  },

  searchhelpimage:{
    position:"absolute" as "absolute",
    left:"9px",
    top:"9px",
    width:"24px",
    height:"24px"
  },

  helpcenteroptionboxcontainer: {
    width: "70%",
    display: "flex",
    gap: "24px",
    alignItems: "center"
  }
}
// Customizable Area End