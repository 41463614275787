Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings2";
exports.labelBodyText = "settings2 Body";
exports.btnExampleTitle = "CLICK ME";
exports.getARide = 'Get a ride';
exports.myTrips = 'My trips';
exports.contact = 'Contact';
exports.personalInfo = 'Personal Information';
exports.changePassword = 'Change Password';
exports.homeAddress = 'Home Address';
exports.notifications = 'Notifications';
exports.privacyAndPolicy = 'Privacy and Security';
exports.contactUs = 'Contact Us ';
exports.logOut = 'Log Out';
exports.editProfile = 'Edit Profile';
exports.tripHistory = 'Trip History';
exports.favLocation = 'Favorite Locations';
exports.notifications = 'Notifications';
exports.helpCenter = 'Help Center';
exports.settings = 'Settings';
exports.color1 = '87C122';
exports.color2 = '#000000';
exports.testsection1 = 'Test Section 1 (Edit Profile)';
exports.testsection2 = 'Test Section 2 (Trip History)';
exports.testsection3 = 'Test Section 3 (Favorite Locations)';
exports.testsection4 = 'Test Section 4 (Notifications)';
exports.testsection5 = 'Test Section 5 (Help Center)';
// Customizable Area End